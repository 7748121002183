import React from "react";
import { Outlet } from "react-router-dom";
import HeaderBar from "./HeaderBar";
import AsideBar from "./AsideBar";

function LayoutOfAsideAndHeader() {
    return (
        <>
            <HeaderBar />
            <AsideBar />
            <Outlet />
        </>
    )
}
export { LayoutOfAsideAndHeader}