const axios = require('axios');

const baseURL = process.env.REACT_APP_API_URL;
const user = JSON.parse(localStorage.getItem('i2r-user') || JSON.stringify({id:null, name: ''}));
const authid = (user && user.id>0) ? user.id : null;

const instance =  axios.create({
    baseURL: `${baseURL}`,
    headers: localStorage.getItem('i2r-token') ? {
        'Authorization': 'Bearer ' + localStorage.getItem('i2r-token'),
        'Content-Type': 'application/json',
        'AuthId': authid
    } : 
    { 
        'Content-Type': 'application/json', 
        'AuthId': authid 
    },
});

export const fetchData = async (url = '', payload, method = 'post', callback) => {
    
    let headers =  { 
        'Content-Type': 'application/json', 
        'AuthId': authid 
    }
    if (localStorage.getItem('i2r-token')){
        headers = { ...headers, 'Authorization': 'Bearer ' + localStorage.getItem('i2r-token') }
    }

    try {
        let response = await axios({
            method: method,
            url: `${baseURL}${url}`,
            headers: headers,
            data: payload
        });
        callback(response.data);
    } catch (err) {
        return err;
    }
};
export const postFileData = async (url = '', payload, method = 'post', callback) => {
    let headers =  { 
        'Accept': 'application/json',
        'responseType': 'blob',
        'contentType': false, 
        'processData': false,
        'AuthId': authid 
    }
    if (localStorage.getItem('i2r-token')){
        headers = { ...headers, 'Authorization': 'Bearer ' + localStorage.getItem('i2r-token') }
    }
    try {
        let response = await axios({
            method: method,
            url: `${baseURL}${url}`,
            headers: headers,
            data: payload
        });
        callback(response.data);
    } catch (err) {
        return err;
    }
};

export const postRequest = async (url = '', payload, callback) => {
    let headers =  { 
        'Content-Type': 'application/json', 
        'AuthId': authid 
    }
    if (localStorage.getItem('i2r-token')){
        headers = { ...headers, 'Authorization': 'Bearer ' + localStorage.getItem('i2r-token') }
    }

    try {
        let response = await axios({
            method: 'post',
            url: `${baseURL}${url}`,
            headers: headers,
            data: payload
        });
        callback(response.data);
    } catch (err) {
        return err;
    }
};

export const getRequest = async (url = '', callback) => {
    let headers =  { 
        'Content-Type': 'application/json', 
        'Access-Control-Allow-Origin': '*',
        'AuthId': authid 
    }
    if (localStorage.getItem('i2r-token')){
        headers = { ...headers, 'Authorization': 'Bearer ' + localStorage.getItem('i2r-token') }
    }

    try {
        let response = await axios({
            method: 'get',
            url: `${baseURL}${url}`,
            headers: headers
        });
        callback(response.data);
    } catch (err) {
        return err;
    }
};

export const sendPutRequest = async (url = '', payload, callback) => {
    try {
        let response = await instance.put(`${baseURL}${url}`, payload );
        callback(response.data);
    } catch (err) {
        return err;
    }
};

export const deleteRequest = async (url = '', callback) => {
    try {
        try {
            let response = await instance.delete(`${baseURL}${url}`);
            callback(response.data);
        } catch (err) {
            return err;
        }
    } catch (err) {
        return err;
    }
};