 import React,{ useContext } from 'react'; 
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { Context } from '../../components/Context';

function Dashboard() {
    window.document.title = "Dashboard | I2R Portal";
    const [user] = useContext(Context);

    return(
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between mb-md-2">
                        <div className="col mb-2">
                            <h4 className="page-heading">Dashboard 
                                <OverlayTrigger placement="right" overlay={<Tooltip> Say Hello !!</Tooltip>}>
                                    <span className='ms-4 btn btn-outline-primary btn-sm rounded'>Hello</span>
                                </OverlayTrigger>
                            </h4>
                        </div>
                    </div>

                    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-xxl-2 g-3 g-sm-4">
                        <div className="col">
                            <div className="bg-white d-flex align-items-center h-100 p-3 shadow-sm border-start border-1 border-info">
                                <div className="d-block">
                                    <h5 className="fw-bold mb-1" style={{textTransform: 'capitalize'}}>Hello {user && user.name}</h5>
                                    <p className="m-0 opacity-80">It's good to see you again!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> 
        </>
    )   
}
export default Dashboard