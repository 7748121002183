import React, { useState, useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import { getRequest }  from './Services/Api';
import { Context } from './Context';

import { LayoutOfAsideAndHeader} from './LayoutOfAsideAndHeader';
import Login from '../modules/Auth/Login';
import ForgotPassword from '../modules/Auth/ForgotPassword';
import ResetPassword from '../modules/Auth/ResetPassword';
import ProfileResetPassword from '../modules/MyProfile/ResetPassword';

import Dashboard from '../modules/Dashboard/Dashboard';

import Users from '../modules/Users/Index';
import MyProfile from '../modules/MyProfile/MyProfile';
import ManageStaff from '../modules/Users/ManageStaff';

import SiteSettings from '../modules/SiteSettings/SiteSettings';
import Permissions from '../modules/SiteSettings/Permissions';

import ErrorPage from './ErrorPage';

export default function Routing() {
    
    const [context, setContext] = useState(JSON.parse(localStorage.getItem('i2r-user') || JSON.stringify({id:0, name: ''})));
    const [routLoader, setRoutLoader] = useState(false);
    let token = localStorage.getItem('i2r-token');
   
    useEffect(() => {
        if(token){
            getRequest('users/getuser', (response)=> {
                if(response.data.status == 200){  
                    setContext(previousState => ({
                        ...previousState,
                        user: response.data.user
                    }))
                }
                setRoutLoader(true);
            }, (err) => {
                //console.log(err)
            })
        }
    },[routLoader]);

    return (
        <>  
            <Context.Provider value={[context, setContext]} >
                <Routes>
                    <Route path="/" caseSensitive={false} element={<Login />} />
                    <Route path="/login" caseSensitive={false} element={<Login />} />
                    <Route path="/forgot-password" caseSensitive={false} element={<ForgotPassword />} />
                    <Route path="/reset-password/:token" caseSensitive={false} element={<ResetPassword />} />
                
                    <Route path="/admin/" caseSensitive={false} element={<LayoutOfAsideAndHeader  />}>
                    
                        <Route path="dashboard" caseSensitive={false} element={<Dashboard />} />

                        <Route path="users/list" caseSensitive={false} element={<Users />} />
                        <Route path="users/staff" caseSensitive={false} element={<ManageStaff />} />

                        <Route path="my-profile" caseSensitive={false} element={<MyProfile />} />
                        <Route path="password-reset" caseSensitive={false} element={<ProfileResetPassword />} />
                        
                        <Route path="settings/portal-settings" caseSensitive={false} element={<SiteSettings />} />
                        <Route path="settings/permissions" caseSensitive={false} element={<Permissions />} />

                        {/* <Route path="ads/ads" caseSensitive={false} element={<Ads />} /> */}
                    </Route>
                    <Route path="*" element={<ErrorPage />} />
                </Routes>
            </Context.Provider>
        </>
    )
}