import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { getRequest }  from '../components/Services/Api'; 

function MetaData() {
    const [ reload, setReload ] = useState(false);
    const [setting, setSetting] = useState();
    useEffect(() => {
        getRequest('setting/get', (response)=> {
            if(response.success){
                setSetting(response.data);
                setReload(true);
            }
        }); 
    }, [reload]);

    return (
        <>
            { setting && <HelmetProvider>
                <Helmet>
                    <title>{setting.site_name}</title>
                    <meta name="description" content={setting.description}/>
                    <meta name="keywords" content= {setting.keywords} />
                </Helmet>
            </HelmetProvider>
            }
        </>
    )
}
export default MetaData;